import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';
// import { TranslationService } from './modules/i18n/translation.service';
// language list
// import { locale as enLang } from './modules/i18n/vocabs/en';
// import { locale as chLang } from './modules/i18n/vocabs/ch';
// import { locale as esLang } from './modules/i18n/vocabs/es';
// import { locale as jpLang } from './modules/i18n/vocabs/jp';
// import { locale as deLang } from './modules/i18n/vocabs/de';
// import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { filter, map } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";
import { CMS_TITLE, environment } from 'src/environments/environment';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  defaultTitle = CMS_TITLE;

  constructor(
    // private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    // register translations
    // this.translationService.loadTranslations(
    // enLang,
    // chLang,
    // esLang,
    // jpLang,
    // deLang,
    // frLang
    // );
  }

  ngOnInit() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.setPageTitle();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  //dynamic page title
  private setPageTitle(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;

        if (!child) {
          return this.activatedRoute.snapshot.data.title || this.defaultTitle;
        }

        while (child.firstChild) {
          child = child.firstChild;
        }

        if (child.snapshot.data.title) {
          return child.snapshot.data.title || this.defaultTitle;
        }
      })
    ).subscribe((title: string) => this.titleService.setTitle(this.defaultTitle));
  }

}
