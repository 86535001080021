// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appVersion: 'v1.0.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    recaptcha: '6LcRAYYgAAAAAKwVyCRV2XSO31HVPfnn_v8gVKOv',
    isMockEnabled: false,

    //apiUrl: 'https://localhost:5005',

    //NEXT cms
    apiUrl: 'https://cmstosla2024.agencylook.org/api',
    fileUrl: 'https://cmstosla2024.agencylook.org',
    previewUrl: 'https://tosla2024.agencylook.net',
    imgUrl: 'https://tosla2024.agencylook.org/',

    //test cms
    /* apiUrl: 'https://cmstosla2024.agencylook.org/api',
    fileUrl: 'https://cmstosla2024.agencylook.org',
    previewUrl: 'https://tosla2024.agencylook.net',
    imgUrl: 'https://tosla2024.agencylook.org/', */

    /* apiUrl: 'https://testcms.tosla.com/api',
    fileUrl: 'https://testcms.tosla.com',
    previewUrl: 'https://test.tosla.com',
    imgUrl: 'https://test.tosla.com', */

};

export const CMS_TITLE = "Tosla 2024";
export const CMS_LOGO_URL = "assets/logo.png";
export const CMS_ENV = "prod";
